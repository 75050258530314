import React, { useEffect, useState } from "react"

import styled from "styled-components"

import Gallery from "../../components/Gallery"
import Layout from "../../components/Layout"
const contentful = require("contentful")

const client = contentful.createClient({
  space: "dordt7t2eld8",
  accessToken: `${process.env.GATSBY_PREVIEW_CONTENTFUL_ACCESS_TOKEN}`, // prev

  host: "preview.contentful.com",
})

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`
const SelectorButtons = styled.div`
  border: 1px solid;
`

const Preview = () => {
  const contentTypes = ["documentary", "branding"]
  const [slectedCType, setslectedCType] = useState("documentary")
  const [resetCnt, setresetCnt] = useState(0)

  const [images, setImages] = useState([])

  console.log(images)

  useEffect(() => {
    client
      .getEntries()
      .then(response => {
        console.log(response.items)
        setImages(
          response.items
            .filter(
              i =>
                i?.sys?.contentType?.sys?.id === slectedCType &&
                i?.sys?.locale === "en-US"
            )
            .map(i => {
              console.log(i)
              return {
                gridHeight: i.fields.gridHeight,
                gridWidth: i.fields.gridWidth,
                order: i.fields?.order ? i.fields.order : 1,
                src: i.fields.image.fields.file.url,
                id: i.sys.id,
              }
            })
            .sort((a, b) => a.order - b.order)
        )
      })
      .catch(console.error)
  }, [slectedCType, resetCnt])

  return (
    <div>
      {contentTypes.map(type => (
        <SelectorButtons
          onClick={() => setslectedCType(type)}
          style={{ color: slectedCType === type ? "red" : "black" }}
        >
          Go to -- {type}
        </SelectorButtons>
      ))}
      <SelectorButtons onClick={() => setresetCnt(resetCnt + 1)}>
        Refresh
      </SelectorButtons>
      <Layout title="" fluid={true}>
        <Container>
          <Gallery images={images}></Gallery>
        </Container>
      </Layout>
    </div>
  )
}

export default Preview
